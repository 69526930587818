import React from 'react';

const Component = ({ log }) => (
  <div className="card m-0">
    <div className="card-header pt-0">
      <h6 className="card-title my-3">Log</h6>
    </div>
    <hr className="m-0" />
    <div className="card-body log p-3">
      {log.map((item, idx) => (
        <div key={idx}>
          <div className="text-dark text-xsm">{item.time}</div>
          <div className="text-danger text-sm"><span className="text-dark mr-2">topic:</span>{item.topic}</div>
          <div className="text-info text-sm"><span className="text-dark mr-2">payload:</span>{item.payload}</div>
          <hr className="my-1" />
        </div>
      ))}
    </div>
  </div>
);

export default Component;
