import React from 'react';

// import Subscribe from '../subscribe';
import Publish from '../publish';

const Component = ({ list, handlers }) => (
  <div className="row">
    <div className="col-md-6">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Log</h5>
        </div>
        <hr className="m-0" />
        <div className="card-body">
          {list.map((item, idx) => (
            <div key={idx}>
              <div className="text-danger"><span className="text-dark mr-2">topic:</span>{item.topic}</div>
              <div className="text-info"><span className="text-dark mr-2">payload:</span>{item.payload}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="col-md-6">
      {/*<Subscribe />*/}
      <Publish />
    </div>
  </div>
);

export default Component;
