const joi = require('joi');

module.exports = joi.object({
  username: joi.string()
    .min(1)
    .max(25)
    .required(),
  password: joi.string()
    .min(5)
    .max(25)
    .required(),
  email: joi.string().lowercase()
    .min(5)
    .max(255)
    .required()
    .email({ tlds: {allow: false} }),
}).options({ abortEarly: false, stripUnknown: true });
