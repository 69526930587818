import React from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';

const Component = () => {
  const { t } = useTranslation();

  return <View t={t} />;
};

export default Component;
