import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { callApi } from '../api';

const AUTH_STATE_PATH = ['user'];

const initialState = {
  data: {},
  pending: false,
  success: false,
  error: null,
};

const useApiRequest = (successCallback) => {
  const [state, setState] = useState(initialState);
  const user = useSelector((store) => pathOr({}, AUTH_STATE_PATH)(store));

  const pendingFn = () => setState({ ...initialState, pending: true });
  const errorFn = (error) => setState({ ...initialState, error });
  const successFn = useCallback((result) => {
    setState({ ...initialState, data: result, success: true });
    if (successCallback instanceof Function) {
      successCallback(result);
    }
  }, [successCallback]);

  const execute = useCallback((api, body) => {
    return callApi(api, body, user, pendingFn, successFn, errorFn);
  }, [user, successFn]);
  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  return { state, resetState, execute };
};

export default useApiRequest;
