import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ClientView from './clientView';

const Component = () => (
  <Switch>
    <Route path="/client"><ClientView /></Route>
    <Redirect to="/client" />
  </Switch>
);

export default Component;
