import React from 'react';
import { isEmpty } from 'ramda';

import { Form } from '../../../../components';

const Component = ({ form, connection, handlers }) => (
  <div className="card">
    <div className="card-header pt-0 d-flex justify-content-between align-items-center">
      <h6 className="card-title my-3">Connect</h6>
      {isEmpty(connection) && (
        <span className="text-danger">Not connected.</span>
      )}
    </div>
    <hr className="m-0" />
    {!isEmpty(connection) && (
      <div>
        <div className="card-header pt-0 my-1">
          <div className="text-xsm">clientId: <span className="font-weight-bold">{connection.clientId}</span></div>
          <div className="d-flex align-items-center text-xsm">
            <div className="mr-3">username: <span className="font-weight-bold">{connection.username}</span></div>
            <div>password: <span className="font-weight-bold">{connection.password}</span></div>
          </div>
        </div>
        <hr className="m-0" />
      </div>
    )}
    <div className="card-body p-2 mb-1">
      <form onSubmit={form.handleSubmit(handlers.onSubmit)}>
        <div className="form-group m-1 py-1">
          <input name="clientId" ref={form.register} maxLength={25} className="form-control" placeholder="client id"/>
        </div>
        <div className="row m-1">
          <div className="form-group m-0 py-1 pl-0 pr-1 col-6">
            <input name="username" ref={form.register} maxLength={25} className="form-control" placeholder="username"/>
          </div>
          <div className="form-group m-0 py-1 pr-0 pl-1 col-6">
            <input name="password" ref={form.register} maxLength={25} className="form-control" placeholder="password"/>
          </div>
        </div>
        <div className="form-group m-1 py-1">
          <Form.Submit form={form} className="m-0" onSubmit={form.handleSubmit(handlers.onSubmit)}>
            Connect
          </Form.Submit>
        </div>
      </form>
    </div>
  </div>
);

export default Component;
