import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Log from './log';

const Component = () => (
  <Switch>
    <Route path="/messages"><Log /></Route>
    <Redirect to="/messages" />
  </Switch>
);

export default Component;
