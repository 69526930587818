import React from 'react';
import { NavLink } from 'react-router-dom';

import { SortField, FilterField } from '../../../../components/Table';

const Component = ({ list, total, filters, sort, handlers }) => (
  <div className="col-md-12 p-0">
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Users (<b className="text-info">{list.length} : {total}</b>)</h5>
          <div>
            <NavLink className="btn btn-round btn-info" to={`/users/add`}>
              <i className="nc-icon nc-simple-add"/>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary clickable">
            <tr>
              <SortField className="text-center" name="id" label="Id" sort={sort} handlers={handlers} />
              <SortField className="w-25 text-left" name="username" label="Username" sort={sort} handlers={handlers} />
              <SortField className="w-25 text-left" name="email" label="E-mail" sort={sort} handlers={handlers} />
              <SortField className="text-center" name="created" label="Created" sort={sort} handlers={handlers} />
              <SortField className="text-center" name="updated" label="Updated" sort={sort} handlers={handlers} />
              <th className="text-center">@</th>
            </tr>
            <tr>
              <FilterField name="id" filters={filters} handlers={handlers} />
              <FilterField name="username" filters={filters} handlers={handlers} />
              <FilterField name="email" filters={filters} handlers={handlers} />
              <th />
              <th />
              <th />
            </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx}>
                <td className="text-left">
                  <NavLink className="text-danger" to={`/users/edit/${data.id}`}>{data.id}</NavLink>
                </td>
                <td className="text-left">{data.username}</td>
                <td className="text-left">{data.email}</td>
                <td className="text-center text-nowrap">{data.created}</td>
                <td className="text-center text-nowrap">{data.updated}</td>
                <td className="text-center text-nowrap">
                  <button className="btn btn-xsm btn-info" onClick={handlers.onLogin(data.id)}>
                    <i className="nc-icon nc-button-play"/>
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
