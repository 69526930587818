import React from 'react';
import { isEmpty } from 'ramda';

import Connect from '../connect';
import Subscribe from '../subscribe';
import Publish from '../publish';
import Log from '../log';

const Component = ({ log, connection, subscriptions, handlers }) => (
  <div className="container p-0">
    <div className="row">
      <div className="col-md-4">
        <Connect connection={connection} handlers={handlers} />
        <Subscribe connection={connection} subscriptions={subscriptions} handlers={handlers} />
        <Publish connection={connection} handlers={handlers} />
      </div>
      <div className="col-md-4">
        <div className="card m-0">
          <div className="card-header pt-0 d-flex justify-content-between align-items-center">
            <h6 className="card-title my-3">Listener</h6>
            {isEmpty(connection) && (
              <span className="text-danger">Not connected.</span>
            )}
          </div>
          <hr className="m-0" />
          <div className="card-body log p-3">
            {log.map((item, idx) => (
              <div key={idx}>
                <div className="text-dark text-xsm">{item.time}</div>
                <div className="text-danger text-sm"><span className="text-dark mr-2">topic:</span>{item.topic}</div>
                <div className="text-info text-sm"><span className="text-dark mr-2">payload:</span>{item.payload}</div>
                <hr className="my-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <Log />
      </div>
    </div>
  </div>
);

export default Component;
