const joi = require('joi');

const schema = joi.object({
  clientId: joi.string()
    .min(1)
    .max(25)
    .required(),
  username: joi.string()
    .min(1)
    .max(25)
    .required(),
  password: joi.string()
    .min(1)
    .max(255)
    .required(),
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
