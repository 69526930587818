import React from 'react';
import { NavLink } from 'react-router-dom';

import { SortField, FilterField } from '../../../../components/Table';

const Component = ({ list, total, filters, sort, handlers }) => (
  <div className="col-md-12 p-0">
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Devices (<b className="text-info">{list.length} : {total}</b>)</h5>
          <div>
            <NavLink className="btn btn-round btn-info" to={`/devices/add`}>
              <i className="nc-icon nc-simple-add"/>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary clickable">
            <tr>
              <SortField className="text-center" name="id" label="Id" sort={sort} handlers={handlers} />
              <SortField className="w-25 text-left" name="name" label="Name" sort={sort} handlers={handlers} />
              <SortField className="w-25 text-left" name="type" label="Type" sort={sort} handlers={handlers} />
              <SortField className="w-auto text-left" name="mac" label="MAC" sort={sort} handlers={handlers} />
              <SortField className="w-auto text-left" name="ip" label="IP" sort={sort} handlers={handlers} />
              <SortField className="w-25 text-left" name="owner" label="Owner" sort={sort} handlers={handlers} />
              <SortField className="text-center" name="created" label="Created" sort={sort} handlers={handlers} />
              <SortField className="text-center" name="updated" label="Updated" sort={sort} handlers={handlers} />
            </tr>
            <tr>
              <FilterField name="id" filters={filters} handlers={handlers} />
              <FilterField name="name" filters={filters} handlers={handlers} />
              <FilterField name="type" filters={filters} handlers={handlers} />
              <FilterField name="mac" filters={filters} handlers={handlers} />
              <FilterField name="ip" filters={filters} handlers={handlers} />
              <FilterField name="owner" filters={filters} handlers={handlers} />
              <th />
              <th />
            </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx}>
                <td className="text-left">
                  <NavLink className="text-danger" to={`/devices/edit/${data.id}`}>{data.id}</NavLink>
                </td>
                <td className="text-right">{data.name}</td>
                <td className="text-right">{data.type}</td>
                <td className="text-right">{data.mac}</td>
                <td className="text-right">{data.ip}</td>
                <td className="text-right">{data.owner}</td>
                <td className="text-center text-nowrap">{data.created}</td>
                <td className="text-center text-nowrap">{data.updated}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
