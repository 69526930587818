import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

import View from './view';
import schema from './schema';

const Component = ({ connection, handlers: parentHandlers }) => {
  const form = useForm({ mode: 'all', reValidateMode: 'onChange', resolver: joiResolver(schema) });
  const handlers = {
    onSubmit: (data) => {
      form.reset();
      parentHandlers.onConnect(data);
    },
  };

  return <View form={form} connection={connection} handlers={handlers} />;
};

export default Component;
