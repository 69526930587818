import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import List from './list';
import Form from './form';

const Component = () => (
  <Switch>
    <Route path="/users/add"><Form /></Route>
    <Route path="/users/edit/:id"><Form /></Route>
    <Route path="/users"><List /></Route>
    <Redirect to="/users" />
  </Switch>
);

export default Component;
