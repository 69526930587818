import React, { useState, useEffect } from 'react';

import View from './view';
import * as messenger from '../../../../../utils/messenger';

const Component = () => {
  const [list, setList] = useState([]);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (message !== null) {
      const parsed = JSON.parse(message);
      setList(l => [{ topic: parsed.topic, payload: parsed.payload }, ...l]);
      setMessage(null);
    }
  }, [message]);

  useEffect(() => {
    messenger.subscribe([{ name: 'log', callback: setMessage }]);
    return () => messenger.unsubscribe(['log']);
  }, []);

  const handlers = {
    onClear: () => {},
  };

  return <View list={list} handlers={handlers} />;
};

export default Component;
