import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

import View from './view';
import schema from './schema';
import { api as endpoints } from '../../../../../utils/api';
import { useApiRequest } from '../../../../../utils/hooks';

const Component = () => {
  const { execute } = useApiRequest();
  const form = useForm({ mode: 'all', reValidateMode: 'onChange', resolver: joiResolver(schema) });
  const handlers = {
    // onSubmit: (data) => messenger.publish(data.topic, data.payload),
    onSubmit: (data) => execute(endpoints.messages.create(123), data),
  };

  return <View form={form} handlers={handlers} />;
};

export default Component;
