import React from 'react';

import Menu from './menu';
import Content from './pages';

const Component = () => (
  <div className="wrapper">
    <Menu />
    <div className="main-panel">
      <Content />
    </div>
  </div>
);

export default Component;
