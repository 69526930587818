import React from 'react';

import * as Content from './pages';
import Logo from '../components/logo/horizontal';
import config from '../../config';
import Footer from './footer';

const Component = ({ t }) => (
  <div className="wrapper wrapper-full-page theme-bg">
    <div className="full-page">
      <header className="header-area header_fix">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-6">
              <div className="logo">
                <div className="logo new">
                  <a href={config.WEB_ROOT}><Logo color="#fff" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-6">
              <div className="header-btn"/>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="col-lg-4 col-md-6 ml-auto mr-auto">
            <Content.Login />
          </div>
        </div>
      </div>
    </div>
    <Footer t={t} />
  </div>
);

export default Component;
