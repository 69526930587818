import React from 'react';

import { Form } from '../../../../components';
import { isEmpty } from 'ramda';

const Component = ({ form, connection, subscriptions, handlers }) => (
  <div className="card">
    <div className="card-header pt-0 d-flex justify-content-between align-items-center">
      <h6 className="card-title my-3">Subscriptions</h6>
      {isEmpty(connection) && (
        <span className="text-danger">Not connected.</span>
      )}
    </div>
    <hr className="m-0" />
    {!isEmpty(subscriptions) && (
      <div>
        <div className="card-header pt-0 my-1">
          {Object.keys(subscriptions).map(topic => (
            <div key={topic} className="text-xsm">topic: <span className="font-weight-bold">{topic}</span></div>
          ))}
        </div>
        <hr className="m-0" />
      </div>
    )}
    <div className="card-body p-2">
      <div className="form-group row align-items-center justify-content-between m-1">
        <div className="col-7 p-0">
          <input name="topic" ref={form.register} disabled={isEmpty(connection)} maxLength={25} className="form-control" placeholder="topic"/>
        </div>
        <div className="col-auto p-0 text-right">
          <Form.Submit form={form} className="m-0" onSubmit={form.handleSubmit(handlers.onSubmit)}>
            Subscribe
          </Form.Submit>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
