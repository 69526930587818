import React, { useState, useEffect } from 'react';
import moment from 'moment';

import View from './view';
import mqttClient from '../messenger';

const msgClient = mqttClient();

const Component = () => {
  const [state, setState] = useState({ connectionOptions: {}, subscriptions: {} });
  const [log, setLog] = useState([]);
  const [logMessage, setLogMessage] = useState(null);

  useEffect(() => {
    return () => msgClient.disconnect();
  }, []);

  useEffect(() => {
    if (logMessage !== null) {
      const parsed = JSON.parse(logMessage.toString());
      setLog(l => [{ time: moment().format('YYYY.MM.DD hh:mm:ss'), topic: parsed.topic, payload: parsed.message }, ...l]);
      setLogMessage(null);
    }
  }, [logMessage]);

  const handlers = {
    onClear: () => {},
    onConnect: (options) => msgClient.connect(options, [], setState),
    onSubscribe: (topic) => msgClient.subscribe([{ name: topic, callback: setLogMessage }]),
    onPublish: (topic, message) => msgClient.publish(topic, message),
  };

  return <View log={log} connection={state.connectionOptions} subscriptions={state.subscriptions} handlers={handlers} />;
};

export default Component;
