import React from 'react';

import Auth from './auth';
import Dashboard from './app';

const View = ({ authenticated }) => (
  authenticated ? <Dashboard /> : <Auth />
);

export default View;
