import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';
import config from '../../../../../config';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => {
    execute(endpoints.devices.loadList());
  }, [execute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      created: moment(entity.created).format('YYYY-MM-DD HH:mm:ss'),
      updated: moment(entity.updated).format('YYYY-MM-DD HH:mm:ss'),
    })));
  } , [state.data, setData]);

  const [ sort, setSort ] = useState({ name: '', asc: true });
  const [ filters, setFilters ] = useState({ id: '', name: '', type: '', mac: '', ip: '', owner: '' });

  const total = data.length;
  const list = data
    .filter(entity => (
      (!filters.id || entity.id.toLowerCase().includes(filters.id.toLowerCase())) &&
      (!filters.name || entity.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.type || entity.type.toLowerCase().includes(filters.type.toLowerCase())) &&
      (!filters.mac || entity.mac.toLowerCase().includes(filters.mac.toLowerCase())) &&
      (!filters.ip || entity.ip.toLowerCase().includes(filters.ip.toLowerCase())) &&
      (!filters.owner ||
        ((entity.owner === null) && (filters.owner.trim() === '')) ||
        entity.owner.toLowerCase().includes(filters.owner.toLowerCase()))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.name] < b[sort.name])) || (!sort.asc && (a[sort.name] > b[sort.name])) ? -1 : 1
    );

  const { state: loginState, execute: loginExecute } = useApiRequest();
  useEffect(() => {
    if (loginState.data.jwt) {
      window.open(`${config.APP_ROOT}/ext/${loginState.data.jwt}`);
    }
  }, [loginState]);

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ name: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),

    onLogin: (userId) => () => {
      loginExecute(endpoints.auth.appLogin(userId));
    },
  };

  return <View list={list} total={total} sort={sort} filters={filters} handlers={handlers} />;
};

export default Component;
