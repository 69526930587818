import React from 'react';
import { NavLink } from 'react-router-dom';

import { Form } from '../../../../components';

const Component = ({ id, form, state, users, handlers }) => (
  <div className="col-md-6 p-0">
    <div className="card">
      <div className="card-header border-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{id ? 'Edit device' : 'Add new device'}</h5>
          <div>
            <NavLink className="btn btn-round btn-warning" to={`/devices`}>
              <i className="nc-icon nc-minimal-left"/>
            </NavLink>
          </div>
        </div>
      </div>
      <Form.Progress on={state.pending} />
      <Form.Messages form={form} state={state} onClose={handlers.onReset} />
      <div className="card-body">
        <form onSubmit={form.handleSubmit(handlers.onSubmit)} onChange={handlers.onReset}>
          <label>Name</label>
          <div className="form-group">
            <input name="name" ref={form.register} maxLength={25} className="form-control" placeholder="Enter name"/>
          </div>
          <label>Type</label>
          <div className="form-group">
            <input name="type" ref={form.register} maxLength={25} className="form-control" placeholder="Enter type"/>
          </div>
          <label>MAC</label>
          <div className="form-group">
            <input name="mac" ref={form.register} maxLength={17} className="form-control" placeholder="Enter MAC"/>
          </div>
          <label>IP</label>
          <div className="form-group">
            <input name="ip" ref={form.register} maxLength={20} className="form-control" placeholder="Enter IP"/>
          </div>
          <label>Owner</label>
          <div className="form-group">
            <select name="ownerId" ref={form.register} className="form-control">
              {users.map(user => (<option key={user._id} value={user._id}>{user.username}</option>))}
            </select>
          </div>
        </form>
      </div>
      <div className="card-footer border-top">
        <Form.Submit form={form} state={state} label="Save" onSubmit={form.handleSubmit(handlers.onSubmit)} />
      </div>
    </div>
  </div>
);

export default Component;
