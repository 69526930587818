import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

import View from './view';
import schema from './schema';

const Component = ({ connection, subscriptions, handlers: parentHandlers }) => {
  const form = useForm({ mode: 'all', reValidateMode: 'onChange', resolver: joiResolver(schema) });
  const handlers = {
    onSubmit: ({ topic }) => {
      form.reset({ topic: '' });
      parentHandlers.onSubscribe(topic);
    },
  };

  return <View form={form} connection={connection} subscriptions={subscriptions} handlers={handlers} />;
};

export default Component;
