import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { isEmpty } from 'ramda';

import View from './view';
import schema from './schema';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';

const Component = () => {
  const { id } = useParams();
  const { state: loadState, execute: loadExecute } = useApiRequest();
  useEffect(() => {
    if (id) {
      loadExecute(endpoints.users.load(id));
    }
  }, [loadExecute, id]);

  const { state, resetState, execute } = useApiRequest();
  const [loaded, setLoaded] = useState(false);
  const form = useForm({ mode: 'all', reValidateMode: 'onChange', resolver: joiResolver(schema) });
  useEffect(() => {
    if (!isEmpty(loadState.data) && !loaded) {
      setLoaded(true);
      form.reset({ ...loadState.data, password: '' });
    }
  }, [form, loaded, loadState.data]);

  const handlers = {
    onSubmit: (data) => execute(id ? endpoints.users.update(id) : endpoints.users.create(), data),
    onReset: resetState,
  };

  return <View id={id} form={form} state={state} handlers={handlers} />;
};

export default Component;
