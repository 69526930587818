import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { pathOr } from 'ramda';
import { joiResolver } from '@hookform/resolvers/joi';

import config from '../../config';
import callApi from '../api/callApi';

const AUTH_STATE_PATH = ['user'];
const LANG_STATE_PATH = ['language', 'code'];

const STATE_TIMEOUT = 1000;

const usePrevious = (value) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const useSubmitted = (result, checkSubmit) => {
  const [submitted, setSubmitted] = useState(false);
  const previous = usePrevious(result);

  useEffect(() => {
    if ((previous === null) && (previous !== result) && checkSubmit) {
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), STATE_TIMEOUT);
    }
  }, [previous, result, checkSubmit]);
  return submitted && !!result;
};

const useApiForm = (api, successCallback, validationSchema = null, defaultValues = {}, checkSubmit = false) => {
  const user = useSelector((state) => pathOr({}, AUTH_STATE_PATH)(state));
  const language = useSelector((state) => pathOr(config.LANG, LANG_STATE_PATH)(state));
  const [result, setResult] = useState(null);
  const submitted = useSubmitted(result, checkSubmit);
  const { formState, register, unregister, handleSubmit, errors, setValue, getValues, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: validationSchema ? joiResolver(validationSchema) : undefined,
    defaultValues,
  });

  const onClearMessage = () => setResult(null);

  const submitAction = async (body) => {
    const pendingFn = () => setResult(null);
    const successFn = (result) => {
      setResult({ error: false });
      successCallback(result);
    };
    const errorFn = (error) => {
      console.log('API ERROR', error);
      return setResult({ ...error, error: true });
    };
    await callApi(api, body, user, pendingFn, successFn, errorFn, language);
  };

  return {
    onSubmit: handleSubmit(submitAction),
    onClearMessage,
    form: formState,
    register,
    unregister,
    setValue,
    getValues,
    errors,
    result,
    submitted,
    reset,
  };
};

export default useApiForm;
