const joi = require('joi');

module.exports = joi.object({
  name: joi.string()
    .min(1)
    .max(25)
    .required(),
  type: joi.string()
    .min(1)
    .max(25)
    .required(),
  mac: joi.string()
    .min(17)
    .max(17)
    .required(),
  ip: joi.string()
    .min(5)
    .max(20)
    .required(),
  ownerId: joi.string(),
}).options({ abortEarly: false, stripUnknown: true });
