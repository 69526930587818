import React from 'react';

const Component = ({ color = '#f05a26', height = 50, width = 140 }) => (
  <div style={{ maxHeight: `px`, maxWidth: '100px' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="1000 620 100 550"
      version="1.1"
    >
      <g fill={color}>
        <path d="M518.635,837.846c0.447-0.313,0.887-0.532,1.216-0.861
      c20.593-20.613,41.176-41.235,61.757-61.861c0.108-0.108,0.176-0.268,0.228-0.416c0.044-0.124,0.036-0.265,0.067-0.541
      c-0.595-0.405-1.217-0.895-1.897-1.283c-13.214-7.559-27.266-12.905-42.149-16.129c-9.53-2.064-19.168-3.085-28.891-3.252
      c-7.004-0.12-13.992,0.283-20.958,1.187c-31.448,4.075-58.826,16.908-82.027,38.438c-22.705,21.071-37.282,46.913-44.012,77.14
      c-3.137,14.086-4.019,28.39-2.861,42.741c5.275,65.378,50.973,114.768,106.093,130.623c7.813,2.248,15.735,3.856,23.809,4.858
      c10.323,1.281,20.677,1.428,30.988,0.496c26.554-2.398,50.751-11.393,72.547-26.783c0.518-0.366,1.033-0.746,1.496-1.179
      c0.48-0.448,0.732-1.014,0.564-1.708c-0.299-1.235-0.591-2.473-0.867-3.713c-7.32-32.881,12.096-67.508,46.756-76.848
      c37.451-10.092,72.76,13.816,80.744,48.231c7.813,33.68-12.006,68.878-47.381,78.132c-7.743,2.025-15.653,2.739-23.673,1.6
      c-3.314-0.471-6.624-0.896-9.82-1.901c-1.391-0.437-2.395-0.021-3.491,0.824c-3.529,2.721-7.064,5.441-10.718,7.988
      c-22.868,15.933-47.963,26.805-75.182,32.712c-10.584,2.297-21.311,3.61-32.139,4.312c-10.37,0.672-20.707,0.448-31.012-0.481
      c-26.094-2.354-50.984-9.214-74.377-21.068c-43.868-22.229-76.274-55.664-97.137-100.216
      c-7.252-15.488-12.292-31.716-15.472-48.506c-2.764-14.596-3.808-29.343-3.444-44.196c0.171-7.01,0.579-14.004,1.477-20.954
      c2.313-17.909,6.748-35.281,13.52-52.035c22.998-56.897,69.867-100.999,128.065-120.45c11.18-3.736,22.605-6.476,34.277-8.24
      c12.312-1.861,24.677-2.768,37.117-2.437c6.521,0.173,13.027,0.684,19.518,1.44c10.294,1.199,20.433,3.127,30.418,5.876
      c39.718,10.937,73.303,31.812,100.922,62.331c2.561,2.83,4.954,5.813,7.406,8.742c0.606,0.724,0.531,1.392-0.133,2.105
      c-0.325,0.349-0.658,0.691-0.994,1.03c-0.787,0.791-1.577,1.577-2.365,2.365c-33.554,33.554-67.108,67.108-100.661,100.662
      c-1.014,1.013-1.945,2.129-3.057,3.019c-1.65,1.321-1.667,2.749-1.034,4.641c3.235,9.651,4.179,19.611,3.199,29.693
      c-1.354,13.937-6.267,26.567-14.829,37.692c-11.119,14.447-25.68,23.523-43.418,27.572c-42.743,8.399-78.051-18.843-86.934-53.545
      c-2.23-8.712-2.94-17.492-2.075-26.495c2.669-27.776,21.915-53.081,49.865-62.507c8.857-2.987,17.943-4.146,27.262-3.759
      c7.373,0.305,14.517,1.719,21.463,4.186C517.143,837.378,517.898,837.606,518.635,837.846 M423.397,906.197
      c-0.255,38.077,30.193,67.542,65.978,68.594c36.773,1.082,69.16-27.712,70.017-66.438c0.869-39.313-30.657-68.519-65.945-69.55
      C456.377,837.718,423.814,867.209,423.397,906.197 M657.052,1040.073c20.542,0.318,38.468-15.771,38.818-37.725
      c0.33-20.75-15.781-38.5-37.745-38.805c-22.84-0.315-38.345,18.107-38.812,37.25C618.8,1021.806,635.515,1039.797,657.052,1040.073
      "/>
        <path d="M440.82,906.641c-0.279-27.201,22.292-50.554,50.748-50.518
      c26.154,0.033,50.574,20.86,50.494,50.831c-0.08,29.466-24.028,50.646-50.806,50.513
      C462.294,957.324,440.369,933.465,440.82,906.641 M491.292,877.162c-15.675-0.155-29.66,12.962-29.537,29.879
      c0.12,16.436,13.8,29.627,30.023,29.439c15.096-0.174,29.302-12.225,29.291-29.685C521.059,888.771,506.085,876.84,491.292,877.162
      "/>
        <path d="M948.031,862.279H831.132c0.841,15.899,6.171,28.547,15.99,37.942c9.816,9.396,22.494,14.093,38.033,14.093
      c21.682,0,41.675-6.744,59.985-20.236v32.161c-10.118,6.748-20.146,11.563-30.083,14.455c-9.938,2.891-21.591,4.336-34.961,4.336
      c-18.311,0-33.126-3.794-44.447-11.382c-11.324-7.589-20.389-17.797-27.192-30.625c-6.807-12.828-10.208-27.672-10.208-44.537
      c0-25.295,7.165-45.861,21.5-61.702c14.333-15.838,32.943-23.759,55.83-23.759c22.043,0,39.628,7.71,52.758,23.127
      c13.127,15.419,19.694,36.076,19.694,61.973V862.279z M831.855,842.585h83.654c-0.844-13.127-4.76-23.245-11.744-30.354
      c-6.987-7.105-16.382-10.66-28.186-10.66c-11.806,0-21.472,3.554-28.999,10.66C839.051,819.34,834.142,829.458,831.855,842.585z"/>
        <path d="M995.449,690.092h32.883V942.5h-32.883V690.092z"/>
        <path d="M1063.274,802.655l61.792-60.708v34.329h52.577v29.631h-52.577v81.354c0,19,7.888,28.5,23.669,28.5
      c11.804,0,24.271-3.913,37.4-11.744v30.715c-12.647,7.108-26.441,10.66-41.375,10.66c-15.059,0-27.585-4.396-37.581-13.189
      c-3.134-2.648-5.723-5.629-7.77-8.943c-2.05-3.312-3.766-7.648-5.149-13.009c-1.386-5.358-2.078-15.567-2.078-30.625v-73.717
      h-28.908V802.655z"/>
        <path d="M1256.138,776.276v38.124l1.807-2.891c15.899-25.656,31.8-38.484,47.699-38.484
      c12.404,0,25.354,6.265,38.846,18.791l-17.345,28.909c-11.445-10.841-22.043-16.261-31.8-16.261
      c-10.601,0-19.784,5.059-27.554,15.177c-7.769,10.118-11.653,22.104-11.653,35.955V942.5h-33.064V776.276H1256.138z"/>
        <path d="M1484.415,942.5v-21.32c-6.987,7.645-14.969,13.593-23.94,17.839c-8.975,4.246-17.918,6.372-26.83,6.372
      c-10.479,0-20.146-2.62-28.999-7.859c-8.854-5.24-15.539-12.345-20.056-21.32c-4.517-8.972-6.775-23.878-6.775-44.718v-95.218
      h32.884v94.735c0,17.444,2.498,29.625,7.498,36.542c4.997,6.917,13.76,10.375,26.288,10.375c15.657,0,28.969-7.648,39.931-22.946
      V776.276h32.883V942.5H1484.415z"/>
        <path d="M1710.514,821.807V942.5h-33.064v-92.507c0-18.429-2.47-31.286-7.407-38.575
      c-4.94-7.286-13.551-10.931-25.837-10.931c-6.866,0-13.161,1.567-18.881,4.698c-5.723,3.134-12.258,8.673-19.604,16.623V942.5
      h-32.884V776.276h32.884v21.862c16.74-16.741,33.123-25.114,49.145-25.114c21.077,0,37.4,10,48.964,29.993
      c17.585-20.236,35.955-30.354,55.106-30.354c16.14,0,29.42,5.903,39.84,17.707c10.417,11.806,15.629,29.812,15.629,54.023V942.5
      h-32.884v-98.47c0-13.85-2.831-24.451-8.492-31.8c-5.663-7.346-13.793-11.021-24.392-11.021
      C1735.024,801.21,1722.317,808.076,1710.514,821.807z"/>
        <path d="M821.532,1085.481v-15.591c4.188,2.863,8.473,5.185,12.853,6.96c4.378,1.777,8.065,2.665,11.058,2.665
      c3.101,0,5.766-0.742,7.997-2.228c2.23-1.483,3.346-3.261,3.346-5.329c0-2.12-0.722-3.884-2.163-5.29
      c-1.442-1.404-4.557-3.433-9.344-6.085c-9.576-5.196-15.845-9.638-18.81-13.323c-2.966-3.686-4.448-7.702-4.448-12.052
      c0-5.62,2.244-10.207,6.732-13.761c4.488-3.552,10.268-5.329,17.341-5.329c7.344,0,14.878,2.016,22.604,6.045v14.318
      c-8.813-5.197-16.023-7.796-21.625-7.796c-2.884,0-5.21,0.598-6.977,1.792c-1.769,1.195-2.652,2.774-2.652,4.738
      c0,1.7,0.801,3.319,2.402,4.858c1.602,1.54,4.417,3.396,8.443,5.568l5.306,2.941c12.516,6.894,18.775,14.527,18.775,22.903
      c0,5.99-2.407,10.908-7.222,14.751c-4.815,3.844-11.004,5.765-18.565,5.765c-4.461,0-8.433-0.465-11.914-1.392
      C831.188,1089.685,826.808,1087.975,821.532,1085.481z"/>
        <path d="M1015.818,1017.551h16.664l-53.194,110.487H962.71l25.485-53.021l-28.994-57.466h16.924l20.274,41.337
      L1015.818,1017.551z"/>
        <path d="M1118.307,1085.481v-15.591c4.188,2.863,8.474,5.185,12.854,6.96c4.378,1.777,8.064,2.665,11.057,2.665
      c3.102,0,5.767-0.742,7.998-2.228c2.229-1.483,3.346-3.261,3.346-5.329c0-2.12-0.722-3.884-2.163-5.29
      c-1.442-1.404-4.557-3.433-9.344-6.085c-9.575-5.196-15.845-9.638-18.81-13.323c-2.966-3.686-4.447-7.702-4.447-12.052
      c0-5.62,2.244-10.207,6.732-13.761c4.488-3.552,10.268-5.329,17.341-5.329c7.345,0,14.879,2.016,22.604,6.045v14.318
      c-8.813-5.197-16.022-7.796-21.625-7.796c-2.885,0-5.21,0.598-6.978,1.792c-1.769,1.195-2.652,2.774-2.652,4.738
      c0,1.7,0.801,3.319,2.402,4.858c1.602,1.54,4.417,3.396,8.443,5.568l5.306,2.941c12.517,6.894,18.775,14.527,18.775,22.903
      c0,5.99-2.407,10.908-7.222,14.751c-4.814,3.844-11.004,5.765-18.565,5.765c-4.461,0-8.434-0.465-11.914-1.392
      C1127.963,1089.685,1123.583,1087.975,1118.307,1085.481z"/>
        <path d="M1255.978,1029.164l27.909-26.727v15.113h23.746v13.045h-23.746v35.816c0,8.364,3.562,12.547,10.689,12.547
      c5.331,0,10.962-1.723,16.893-5.171v13.522c-5.712,3.13-11.942,4.693-18.688,4.693c-6.801,0-12.459-1.935-16.974-5.807
      c-1.415-1.166-2.585-2.479-3.509-3.938c-0.926-1.458-1.701-3.367-2.326-5.727c-0.626-2.359-0.938-6.854-0.938-13.483v-32.454
      h-13.057V1029.164z"/>
        <path d="M1465.354,1055.414h-52.798c0.38,7,2.787,12.567,7.222,16.704c4.434,4.136,10.16,6.204,17.178,6.204
      c9.793,0,18.823-2.969,27.093-8.909v14.159c-4.569,2.971-9.099,5.091-13.587,6.363c-4.488,1.273-9.752,1.909-15.791,1.909
      c-8.27,0-14.961-1.67-20.074-5.011c-5.114-3.341-9.209-7.835-12.281-13.483c-3.074-5.647-4.611-12.183-4.611-19.607
      c0-11.136,3.236-20.19,9.711-27.164c6.474-6.973,14.879-10.46,25.216-10.46c9.956,0,17.898,3.394,23.829,10.182
      c5.929,6.788,8.895,15.883,8.895,27.283V1055.414z M1412.883,1046.743h37.783c-0.382-5.779-2.15-10.233-5.305-13.363
      c-3.155-3.128-7.399-4.693-12.73-4.693c-5.332,0-9.698,1.565-13.098,4.693C1416.133,1036.51,1413.916,1040.964,1412.883,1046.743z"
        />
        <path d="M1623.181,1037.596v53.136h-14.934v-40.727c0-8.113-1.115-13.773-3.346-16.983
      c-2.231-3.207-6.12-4.812-11.669-4.812c-3.102,0-5.944,0.689-8.528,2.067c-2.584,1.38-5.536,3.818-8.854,7.318v53.136h-14.853
      v-73.181h14.853v9.625c7.561-7.371,14.96-11.057,22.196-11.057c9.52,0,16.892,4.402,22.114,13.204
      c7.942-8.909,16.24-13.363,24.89-13.363c7.29,0,13.288,2.599,17.994,7.795c4.705,5.198,7.059,13.125,7.059,23.784v43.192h-14.852
      v-43.352c0-6.098-1.279-10.765-3.836-14c-2.558-3.234-6.229-4.853-11.017-4.853
      C1634.251,1028.527,1628.512,1031.551,1623.181,1037.596z"/>
        <path d="M1767.053,1085.481v-15.591c4.188,2.863,8.474,5.185,12.854,6.96c4.378,1.777,8.064,2.665,11.057,2.665
      c3.102,0,5.767-0.742,7.998-2.228c2.229-1.483,3.346-3.261,3.346-5.329c0-2.12-0.722-3.884-2.163-5.29
      c-1.442-1.404-4.557-3.433-9.344-6.085c-9.575-5.196-15.845-9.638-18.81-13.323c-2.966-3.686-4.447-7.702-4.447-12.052
      c0-5.62,2.244-10.207,6.732-13.761c4.488-3.552,10.268-5.329,17.341-5.329c7.345,0,14.879,2.016,22.604,6.045v14.318
      c-8.813-5.197-16.022-7.796-21.625-7.796c-2.885,0-5.21,0.598-6.978,1.792c-1.769,1.195-2.652,2.774-2.652,4.738
      c0,1.7,0.801,3.319,2.402,4.858c1.602,1.54,4.417,3.396,8.443,5.568l5.306,2.941c12.517,6.894,18.775,14.527,18.775,22.903
      c0,5.99-2.407,10.908-7.222,14.751c-4.814,3.844-11.004,5.765-18.565,5.765c-4.461,0-8.434-0.465-11.914-1.392
      C1776.709,1089.685,1772.329,1087.975,1767.053,1085.481z"/>
      </g>
    </svg>
  </div>
);

export default Component;
