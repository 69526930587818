import React from 'react';
import { isEmpty } from 'ramda';

import { Form } from '../../../../components';

const Component = ({ form, connection, handlers }) => (
  <div className="card m-0">
    <div className="card-header pt-0 d-flex justify-content-between align-items-center">
      <h6 className="card-title my-3">Publish</h6>
      {isEmpty(connection) && (
        <span className="text-danger">Not connected.</span>
      )}
    </div>
    <hr className="m-0" />
    <div className="card-body p-2 mb-1">
      <form onSubmit={form.handleSubmit(handlers.onSubmit)}>
        <div className="form-group m-1 py-1">
          <input name="topic" ref={form.register} defaultValue={'hmi-device'} disabled={isEmpty(connection)} maxLength={25} className="form-control" placeholder="topic"/>
        </div>
        <div className="form-group m-1 py-1">
          <textarea name="payload" ref={form.register} disabled={isEmpty(connection)} maxLength={255} className="form-control" placeholder="payload"/>
        </div>
        <div className="form-group m-1 py-1">
          <Form.Submit form={form} className="m-0" onSubmit={form.handleSubmit(handlers.onSubmit)}>
            Send
          </Form.Submit>
        </div>
      </form>
    </div>
  </div>
);

export default Component;
