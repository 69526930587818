import React, { useState, useEffect } from 'react';
import moment from 'moment';
import crypto from 'crypto';

import View from './view';
import mqttClient from '../messenger';

const msgClient = mqttClient();

const Component = () => {
  const [log, setLog] = useState([]);
  const [logMessage, setLogMessage] = useState(null);

  useEffect(() => {
    const hmiSysClient = { clientId: `hmi-sys-app-${crypto.randomBytes(8).toString('hex')}-log`, username: 'hmi-sys-app', password: 'hmi-sys-app' };
    msgClient.connect(hmiSysClient, [{ name: 'log', callback: setLogMessage }]);
    return () => msgClient.disconnect();
  }, []);

  useEffect(() => {
    if (logMessage !== null) {
      const logEntry = JSON.parse(logMessage.toString());
      const parsed = JSON.parse(logEntry.message.toString());
      setLog(l => [{ time: moment().format('YYYY.MM.DD hh:mm:ss'), topic: parsed.topic, payload: parsed.payload }, ...l]);
      setLogMessage(null);
    }
  }, [logMessage]);

  return <View log={log} />;
};

export default Component;
