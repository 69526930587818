import * as mqtt from 'mqtt';
import { isEmpty } from 'ramda';

import config from '../../../../config';

const client = () => {
  let mqttClient = null;
  let subscriptions = {};
  let connectionOptions = {};
  let listener = null;

  const notify = () => {
    if (listener) {
      listener({ connectionOptions, subscriptions });
    }
  };

  const connect = (options, topics, subscriber = null) => {
    listener = subscriber;
    connectionOptions = options;
    mqttClient = mqtt.connect(config.MQTT_ROOT, connectionOptions);

    mqttClient.on('connect', () => {
      notify();
      listen(topics);
    });

    mqttClient.on('message', (topic, message) => {
      if (subscriptions[topic] && subscriptions[topic] instanceof Function) {
        subscriptions[topic](JSON.stringify({ topic, message: message.toString() }));
      }
    });
  };

  const disconnect = () => {
    connectionOptions = {};
    subscriptions = {};
    if (mqttClient) {
      mqttClient.end();
      notify();
    }
  };

  const listen = (topics) => {
    topics.forEach(({ name, callback }) => mqttClient.subscribe(name, (err) => {
      subscriptions = { ...subscriptions, [name]: callback };
      notify();
      if (err !== null) {
        console.log(`ERROR subscribing to topic: ${name}`, err);
      }
    }));
  };

  const subscribe = (topics) => {
    if (mqttClient && mqttClient.connected) {
      listen(topics);
    } else if (!isEmpty(connectionOptions)) {
      connect(connectionOptions, topics);
    } else {
      console.log('MQTT not connected.')
    }
  };

  const unsubscribe = (topics) => {
    if (mqttClient && mqttClient.connected) {
      topics.forEach(name => {
        // TODO: remove topic
        // subscribedTo = { ...subscribedTo, [name]: callback };
        mqttClient.unsubscribe(name, () => {});
      });
    }
  };

  const publish = (topic, message) => {
    if (mqttClient && mqttClient.connected) {
      mqttClient.publish(topic, message);
    } else {
      console.log('MQTT not connected.');
    }
  };

  return { connect, disconnect, subscribe, unsubscribe, publish };
};

export default client;
