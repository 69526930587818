const joi = require('joi');

const schema = joi.object({
  topic: joi.string()
    .min(1)
    .max(25)
    .required(),
  payload: joi.string()
    .min(1)
    .max(255)
    .required(),
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
