import React from 'react';

const View = () => (
  <footer className="footer d-flex align-items-center">
    <span className="copyright pr-1 text-white">© 2019-2021</span>
    <a href="https://www.eltrum.com" target="_blank" rel="noopener noreferrer" className="copyright text-white">&laquo;Eltrum Systems&raquo;</a>
  </footer>
);

export default View;
