import React from 'react';

const Component = ({ form, handlers }) => (
  <div className="card">
    <div className="card-header">
      <h5 className="card-title">Publish</h5>
    </div>
    <hr className="m-0" />
    <div className="card-body">
      <form onSubmit={form.handleSubmit(handlers.onSubmit)}>
        <div className="form-group">
          <input name="topic" ref={form.register} maxLength={25} className="form-control" placeholder="topic"/>
        </div>
        <div className="form-group">
          <textarea name="payload" ref={form.register} maxLength={255} className="form-control" placeholder="payload"/>
        </div>
        <div className="form-group">
          <button className="btn btn-info pull-right" onClick={form.handleSubmit(handlers.onSubmit)}>Send</button>
        </div>
      </form>
    </div>
  </div>
);

export default Component;
