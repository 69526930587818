import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Users from './users';
import Devices from './devices';
import Messages from './messages';
import Client from './client';

const Component = () => (
  <div className="m-4">
    <Switch>
      <Route path="/users"><Users /></Route>
      <Route path="/devices"><Devices /></Route>
      <Route path="/messages"><Messages /></Route>
      <Route path="/client"><Client /></Route>
      <Redirect to="/messages" />
    </Switch>
  </div>
);

export default Component;
